import clsx, { ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Wrapper around clsx that fixes overlapping/conflicting/duplicated
 * tailwind classes.
 */
function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export default cn
